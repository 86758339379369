import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { Notification } from "../model/Notification";
import { mergeMap } from "rxjs/operators";
import { Organisation } from "../model/organisation";
import { SelfServiceOpIamService } from "./self-service-op-iam.service";

@Injectable()
export class NotificationService {

  private notificationApiUrl;

  constructor(private httpClient: HttpClient,
    bfcConfig: BfcConfigurationService,
    private opSelfServiceOpIamService: SelfServiceOpIamService) {
    this.notificationApiUrl = bfcConfig.configuration.notificationApiUrl;
  }

  getNotification(): Observable<Notification> {
    return this.opSelfServiceOpIamService.getCurrentOrganisation().pipe(mergeMap((organisation: Organisation) => {
      const params = new HttpParams().set("eicx", this.opSelfServiceOpIamService.getId(organisation));
      return this.httpClient.get<Notification>(this.notificationApiUrl, { params });
    }));
  }

  setNotification(notification: Notification): Observable<Notification> {
    return this.opSelfServiceOpIamService.getCurrentOrganisation().pipe(mergeMap((organisation: Organisation) => {
      const params = new HttpParams().set("eicx", this.opSelfServiceOpIamService.getId(organisation));
      return this.httpClient.post<Notification>(this.notificationApiUrl, notification, { params });
    }));
  }

}
