export const FR_TRANSLATIONS = {
  "SERVICE_NAME": "Status des stations transformatrices",
  "TRAFO_STATUS": {
    "TITLE": "Stations transformatrices",
    "FAQ_DOWNLOAD_HINT": "FAQ Status des stations transformatrices",
    "FAQ_DOWNLOAD_URL": "https://www.bkw.ch/fileadmin/oneportal/trafo-status/FAQ_Trafostatus_FR.pdf",
  },
  "TAB": {
    "LIST_LABEL": "Liste",
    "MAP_LABEL": "Carte des stations transformatrices",
    "SUBSTATION_LABEL": "Carte des sous-stations",
    "LIST_LABEL_MOBILE": "Liste",
    "MAP_LABEL_MOBILE": "Carte ST",
    "SUBSTATION_LABEL_MOBILE": "Carte SS",
  },
  "ORGANISATION": "Organisation",
  "STATUS_TABLE": {
    "SEARCH": "Recherche",
    "STATION_NR": "Numéro",
    "BEZEICHNUNG": "Nom",
    "UNTERSTATION": "Sous-station",
    "GEMEINDE": "Localité",
    "KOMMENTAR": "Commentaire",
    "STATUS": "Status",
    "LOAD_ERROR": "Erreur au chargement des données.",
  },
  "STATUS_SUMMARY": {
    "OFF": "OFF",
    "ON_48H": "ON: < 48h",
    "ON": "ON",
  },
  "MAP": {
    "LEGEND": "Légende",
    "OFF": "OFF",
    "ON_48H": "ON: < 48h",
    "ON": "ON",
    "SUBSTATION": "Sous-station",
  },
  "STATUS_MAP": {
    "FILTER": "Filtrer les stations transformatrices",
    "ALL": "Toutes stations transformatrices",
    "OFF": "OFF",
    "ON_SINCE_48H": "ON: Moins que 48h",
    "ON": "ON",
    "OFFLINE": "Nous avons constaté une défaillance sur la station transformatrice et faisons actuellement tout notre possible pour la résoudre.",
    "RECENTLY_OFFLINE": "Nous avons constaté une défaillance sur la station transformatrice au cours des 48h précédentes qui a pu être résolu. D'autres coupures ne nous sommes pas connues.",
    "ONLINE": "Aucune panne ne nous est connue en ce moment.",
    "OPEN": "Ouvert",
    "MINIMIZE": "Minimiser",
  },
  "SUBSTATION_MAP": {
    "FILTER": "Filtrer les sous-stations",
  },
  "STATUS": {
    "ONLINE": "ON",
    "OFFLINE": "OFF depuis  {{datetime}}",
    "RECENTLY_OFFLINE": "ON depuis {{datetime}}",
  },
  "COMMENT": {
    "TITLE": "Commentaire station transformatrice",
    "HINT": "Sera effacé automatiquement 48 heures après la fin d'une coupure",
    "CANCEL": "Annuler",
    "SAVE": "Enregistrer",
  },
  "NOTIFICATION": {
    "TITLE": "Notification du status par mail",
    "TEXT": "Veuillez saisir votre adresse mail si vous désirez recevoir des informations concernant un changement de status de stations transformatrices.",
    "EMAIL": "Votre adresse mail",
    "ACTIVE": "Activer le service d'informations par mail",
    "CANCEL": "Annuler",
    "SAVE": "Enregistrer",
  },
  "UNAUTHORIZED": {
    "TITLE": "Pas d'accès",
    "DESCRIPTION": "Le service n'est malheureusement pas accessible.",
  },
  "ELEKTRA": {
    "CONTACT_TEXT": "Questions? Centre d'exploitation",
    "CONTACT_NUMBER": "0844 121 175",
  },
  "BACK_BUTTON": "Retour à l'espace clients",
};