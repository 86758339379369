import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private bfcAuthenticationService: BfcAuthenticationService,
    private router: Router,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  canActivate(): boolean {
    const role: string = this.bfcConfigurationService.configuration.role;
    const allowed: boolean = this.bfcAuthenticationService.hasRealmRole(role);

    if (!allowed) {
      this.router.navigate(["unauthorized"]);
    }
    return allowed;
  }
}