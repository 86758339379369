import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "../../core/services/notification.service";
import { Notification } from "../../core/model/Notification";

@Component({
  // selector - there is none. component is initialized programmatically.
  templateUrl: "notification-dialog.component.html",
  styleUrls: ["notification-dialog.component.scss"],
})
export class NotificationDialogComponent {

  formGroup: FormGroup;

  isUpdatingInBackend = false;

  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder) {
    this.formGroup = this.formBuilder.group({
      email: ["",
        [Validators.email, Validators.maxLength(50)],
      ],
      active: [],
    });

    this.notificationService.getNotification().subscribe(
      (notification: Notification) => {
        this.formGroup.get("email").setValue(notification.email);
        this.formGroup.get("active").setValue(notification.active);
      }, () => {
        // eslint-disable-next-line no-console
        console.error("Unable to get notification");
      }, () => {
        this.isUpdatingInBackend = false;
      },
    );
  }

  save() {
    this.isUpdatingInBackend = true;
    const notification: Notification = {
      email: this.formGroup.get("email").value,
      active: this.formGroup.get("active").value,
    };
    this.notificationService.setNotification(notification).subscribe(
      () => {
        this.dialogRef.close(true);
      }, () => {
        // eslint-disable-next-line no-console
        console.error("Unable to set notification");
      }, () => {
        this.isUpdatingInBackend = false;
      },
    );
  }

}
