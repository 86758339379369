<h2 mat-dialog-title>{{"NOTIFICATION.TITLE" | bfcTranslate }}</h2>
<mat-dialog-content>
    <p>{{"NOTIFICATION.TEXT" | bfcTranslate }}</p>
    <form [formGroup]="formGroup">
        <div class="bfc-form-row">
            <mat-form-field>
                <mat-label>{{ "NOTIFICATION.EMAIL" | bfcTranslate }}</mat-label>
                <input matInput type="email" formControlName="email" required>
            </mat-form-field>
        </div>
        <div class="bfc-form-row">
            <mat-checkbox formControlName="active">
                {{ 'NOTIFICATION.ACTIVE' | bfcTranslate }}
            </mat-checkbox>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close color="primary">{{"NOTIFICATION.CANCEL" | bfcTranslate }}</button>
    <button mat-button color="primary" class="bfc-button-hero"
        (click)="save()" [disabled]="!formGroup.valid" [loading]="isUpdatingInBackend">
        {{"NOTIFICATION.SAVE" | bfcTranslate }}
    </button>
</mat-dialog-actions>
