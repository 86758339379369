import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { InitializerProvider } from "./initialization/initializer-provider";
import { initalizerFactory } from "./initialization/initializer-factory";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { TrafoService } from "./services/trafo.service";
import { SubstationService } from "./services/substation.service";
import { SelfServiceOpIamService } from "./services/self-service-op-iam.service";
import { NotificationService } from "./services/notification.service";
import { InfoWindowService } from "./services/info-window.service";
import { AuthGuard } from "./services/auth-guard.service";

@NgModule({
  imports: [
    HttpClientModule,
  ],
  exports: [],
  declarations: [],
  providers: [
    InitializerProvider,
    GoogleAnalyticsService,
    TrafoService,
    SubstationService,
    SelfServiceOpIamService,
    NotificationService,
    InfoWindowService,
    AuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initalizerFactory,
      deps: [InitializerProvider],
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
