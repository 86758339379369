import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "overview",
    pathMatch: "full",
  },
  {
    path: "overview",
    loadChildren: () =>
      import("./trafo-overview/trafo-overview.module").then((m) => m.TrafoOverviewModule),
  },
  {
    path: "unauthorized",
    loadChildren: () =>
      import("./unauthorized/unauthorized.module").then((m) => m.UnauthorizedModule),
  },
  {
    path: "**",
    redirectTo: "overview",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
