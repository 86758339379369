export const DE_TRANSLATIONS = {
  "SERVICE_NAME": "Trafo Status",
  "TRAFO_STATUS": {
    "TITLE": "Trafostationen",
    "FAQ_DOWNLOAD_HINT": "FAQ Trafo Status",
    "FAQ_DOWNLOAD_URL": "https://www.bkw.ch/fileadmin/oneportal/trafo-status/FAQ_Trafostatus_DE.pdf",
  },
  "TAB": {
    "LIST_LABEL": "Liste",
    "MAP_LABEL": "Trafostationen Karte",
    "SUBSTATION_LABEL": "Unterstationen Karte",
    "LIST_LABEL_MOBILE": "Liste",
    "MAP_LABEL_MOBILE": "Karte TS",
    "SUBSTATION_LABEL_MOBILE": "Karte UST",
  },
  "ORGANISATION": "Organisation",
  "STATUS_TABLE": {
    "SEARCH": "Suche",
    "STATION_NR": "Nummer",
    "BEZEICHNUNG": "Name",
    "UNTERSTATION": "Unterstation",
    "GEMEINDE": "Ort",
    "KOMMENTAR": "Kommentar",
    "STATUS": "Status",
    "LOAD_ERROR": "Fehler beim Laden der Daten.",
  },
  "STATUS_SUMMARY": {
    "OFF": "OFF",
    "ON_48H": "ON: < 48h",
    "ON": "ON",
  },
  "MAP": {
    "LEGEND": "Legende",
    "OFF": "OFF",
    "ON_48H": "ON: < 48h",
    "ON": "ON",
    "SUBSTATION": "Unterstation",
  },
  "STATUS_MAP": {
    "FILTER": "Trafostationen filtern",
    "ALL": "Alle Trafostationen",
    "OFF": "OFF",
    "ON_SINCE_48H": "ON: Weniger als 48h",
    "ON": "ON",
    "OFFLINE": "Derzeit liegt hier ein Ausfall vor. Wir arbeiten an der Behebung.",
    "RECENTLY_OFFLINE": "Wir konnten einen Ausfall innerhalb der letzten 48 Stunden feststellen, der mittlerweile gelöst wurde. Derzeit sind uns keine Ausfälle bekannt.",
    "ONLINE": "Derzeit sind uns keine Ausfälle bekannt.",
    "OPEN": "Öffnen",
    "MINIMIZE": "Minimieren",
  },
  "SUBSTATION_MAP": {
    "FILTER": "Unterstationen filtern",
  },
  "STATUS": {
    "ONLINE": "ON",
    "OFFLINE": "OFF seit {{datetime}}",
    "RECENTLY_OFFLINE": "ON seit {{datetime}}",
  },
  "COMMENT": {
    "TITLE": "Kommentar Trafostation",
    "HINT": "Wird 48 Stunden nach dem Ende des Ausfalls automatisch gelöscht.",
    "CANCEL": "Abbrechen",
    "SAVE": "Speichern",
  },
  "NOTIFICATION": {
    "TITLE": "Statusänderung per E-Mail",
    "TEXT": "Wenn Sie in Zukunft per Email über Statusänderungen der Trafostationen informiert werden wollen, geben Sie bitte Ihre Emailadresse ein.",
    "EMAIL": "Ihre Emailadresse",
    "ACTIVE": "Statusänderung per Email aktivieren",
    "CANCEL": "Abbrechen",
    "SAVE": "Speichern",
  },
  "UNAUTHORIZED": {
    "TITLE": "Kein Zugriff",
    "DESCRIPTION": "Der Service steht Ihnen leider nicht zur Verfügung.",
  },
  "ELEKTRA": {
    "CONTACT_TEXT": "Fragen? Zentrale Leitstelle",
    "CONTACT_NUMBER": "0844 121 175",
  },
  "BACK_BUTTON": "zurück zum Kundencenter",
};