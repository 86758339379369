import { Component, OnInit } from "@angular/core";
import { TrafoStatus } from "../../core/model/TrafoStatus";
import { Status } from "../../core/model/Status";
import { BfcTranslationService } from "@bfl/components/translation";
import { Netstation } from "../../core/model/Netstation";

@Component({
  selector: "app-info-window",
  templateUrl: "./info-window.component.html",
})
export class InfoWindowComponent implements OnInit {
  public netstation: Netstation;

  public translatedText: string;

  constructor(private bfcTranslationService: BfcTranslationService) {
  }

  public createInfoWindowContent(): void {
    if (this.isTrafoStatus(this.netstation)) {
      const trafoStatus = (this.netstation as TrafoStatus);
      let text;
      switch (trafoStatus.status) {
        case Status.ONLINE:
          text = "STATUS_MAP.ONLINE";
          break;
        case Status.RECENTLY_OFFLINE:
          text = "STATUS_MAP.RECENTLY_OFFLINE";
          break;
        case Status.OFFLINE:
          text = "STATUS_MAP.OFFLINE";
          break;
        default:
          throw new Error("Invalid Argument: " + trafoStatus.status);
      }
      this.translatedText = this.bfcTranslationService.translate(text);
    }
  }

  ngOnInit(): void {
    this.createInfoWindowContent();
  }

  private isTrafoStatus(netstation: Netstation): netstation is TrafoStatus {
    return (netstation as TrafoStatus).status !== undefined;
  }

}
