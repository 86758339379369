import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcTranslationService } from "@bfl/components/translation";
import * as moment from "moment";
import { Observable, ReplaySubject, Subject, timer } from "rxjs";
import { mergeMap, take, takeUntil } from "rxjs/operators";
import { TrafoStatus } from "../model/TrafoStatus";
import { Status } from "../model/Status";
import { SelfServiceOpIamService } from "./self-service-op-iam.service";
import { Organisation } from "../model/organisation";

const DATETIME_FORMAT: string = "DD.MM.YYYY HH:mm";

@Injectable()
export class TrafoService implements OnDestroy {

  // reload status every minute
  private interval = 60 * 1000;

  private unsubscribe: Subject<void> = new Subject<void>();

  private trafoStatusListSubject: ReplaySubject<TrafoStatus[]> = new ReplaySubject<TrafoStatus[]>(1);

  private trafoStatusLoadErrorSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  private trafoStationApi;

  constructor(
    private httpClient: HttpClient,
    private bfcTranslationService: BfcTranslationService,
    private selfServiceOpIamService: SelfServiceOpIamService,
    bfcConfigurationService: BfcConfigurationService,
  ) {
    this.trafoStationApi = bfcConfigurationService.configuration.trafoStationApiUrl;

    this.trafoStatusLoadErrorSubject.next(false);

    this.selfServiceOpIamService.organisationChanged.pipe(takeUntil(this.unsubscribe)).subscribe(organisation => {
      this.loadTrafoStatusList(organisation);
    });

    timer(0, this.interval).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.triggerStatusListLoad();
    });
  }

  private triggerStatusListLoad(): void {
    this.selfServiceOpIamService.getCurrentOrganisation().pipe(take(1)).subscribe((organisation: Organisation) => {
      this.loadTrafoStatusList(organisation);
    });
  }

  get trafoStatusList(): Observable<TrafoStatus[]> {
    return this.trafoStatusListSubject.asObservable();
  }

  get trafoStatusLoadError(): Observable<boolean> {
    return this.trafoStatusLoadErrorSubject.asObservable();
  }

  loadTrafoStatusList(organisation: Organisation) {
    const params = new HttpParams().set("eicx", this.selfServiceOpIamService.getId(organisation));
    this.httpClient.get<TrafoStatus[]>(this.trafoStationApi + "/status", { params }).pipe(take(1)).subscribe(
      data => {
        this.trafoStatusListSubject.next(data);
        this.trafoStatusLoadErrorSubject.next(false);
      }, () => {
        // push to error subject
        this.trafoStatusLoadErrorSubject.next(true);
      },
    );
  }

  updateStationComment(stationNr: string, comment: string): Observable<any> {
    return this.selfServiceOpIamService.getCurrentOrganisation().pipe(mergeMap((organisation: Organisation) => {
      const params = new HttpParams().set("eicx", this.selfServiceOpIamService.getId(organisation));
      return this.httpClient.post(this.trafoStationApi + "/" + stationNr + "/comment", comment, { params });
    }));
  }

  formatStatus(trafoStatus: TrafoStatus): string {
    switch (trafoStatus.status) {
      case Status.OFFLINE:
        return this.bfcTranslationService.translate("STATUS.OFFLINE",
          { datetime: moment(trafoStatus.ausfallBeginn).format(DATETIME_FORMAT) });
      case Status.RECENTLY_OFFLINE:
        return this.bfcTranslationService.translate("STATUS.RECENTLY_OFFLINE",
          { datetime: moment(trafoStatus.ausfallEnde).format(DATETIME_FORMAT) });
      case Status.ONLINE:
      default:
        return this.bfcTranslationService.translate("STATUS.ONLINE");
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
