import { Injectable } from "@angular/core";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { TrafoStatus } from "../model/TrafoStatus";
import Map = google.maps.Map;

@Injectable()
export class GoogleAnalyticsService {
  constructor(private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService) {
  }

  public logMapType(map: Map): void {
    this.bfcGoogleAnalyticsService.sendGoogleAnalyticsEvent("TSST", "maptype", map.getMapTypeId() as string);
  }

  public logTrafoClicked(trafoStatus: TrafoStatus): void {
    this.bfcGoogleAnalyticsService.sendGoogleAnalyticsEvent("TSST", "click trafo",
      trafoStatus.bezeichnung + " (" + trafoStatus.bkwStationNummer + ")");
  }

}
